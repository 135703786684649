import React from 'react';
import { Layout } from 'components';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <div className="uk-container uk-padding-large">
      <h2>Sorry, we can't seem to find the page you are looking for.</h2>
      <p>Here are some helpful links instead ...</p>
      <ul>
        <li>
          <Link to={'/'}>Home</Link>
        </li>
        <li>
          <Link to={'/about'}>About</Link>
        </li>
        <li>
          <Link to={'/'}>Jobs</Link>
        </li>
        <li>
          <Link to={'/blog'}>Blog</Link>
        </li>
        <li>
          <Link to={'/contact'}>Contact</Link>
        </li>
      </ul>
    </div>
  </Layout>
);

export default NotFoundPage;
